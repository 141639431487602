<template>
  <div class="w-full h-full bg-gray-100 overflow-y-auto"
    style="height: calc(100vh - 56px)">
    <div
      class="flex flex-col space-y-2 w-2/3 mx-auto mt-4 bg-white px-8 py-4"
      >
      <div class="text-lg text-gray-500 border-b">Account</div>
      <div class="section flex flex-col space-y-2 mb-4">
        <div class="">Profile</div>
        <div class="flex flex-col text-gray-500 space-y-2 px-2">
          <div class="flex flex-row space-x-3 items-center">
            <span class="w-1/4">Email:</span>
            <span>Koder.chen.1984@gmail.com</span>
          </div>
          <div class="flex flex-row space-x-3 items-center">
            <span class="w-1/4">Name:</span>
            <v-text-field
              rounded
              filled
              no-hint
              dense
              hide-details
              placeholder="username"
              class="shrink"
            ></v-text-field>
            <v-btn color="primary">Change</v-btn>
          </div>
          <div class="flex flex-row space-x-3 items-center">
            <span class="w-1/4">Password:</span>
            <v-text-field
              rounded
              filled
              no-hint
              dense
              hide-details
              placeholder="password"
              class="shrink"
            ></v-text-field>
            <v-btn color="primary">Change</v-btn>
          </div>
        </div>

      </div>
      <div class="section flex flex-col space-y-2 mb-4">
        <div class="">Subscription</div>
        <div class="flex flex-row items-center space-x-3 place-items-start bg-gray-100 px-4 py-2 text-gray-500">
          <v-icon>mdi-check-circle</v-icon>
          <div class="flex flex-col">
            <span class="text-xl text-gray-600">Free Trial</span>
            <div>0$/month</div>
            <div>7 days left before expired &middot; since 2022/1/3</div>
          </div>
          <v-btn color="primary">Upgrade</v-btn>
        </div>
        <div class="flex flex-col bg-gray-100">
          <v-subheader>Features</v-subheader>
          <div class="flex flex-col px-4 pb-4 text-gray-500">
            <div class="flex flex-row items-center space-x-3 hover:bg-gray-200 px-2 py-1">
              <v-icon>mdi-database-outline</v-icon>
              <span class="grow">Database storage size</span>
              <span>30GB</span>
            </div>
            <div class="flex flex-row items-center space-x-3 hover:bg-gray-200 px-2 py-1">
              <v-icon>mdi-zip-disk</v-icon>
              <span class="grow">File storage size</span>
              <span>10GB</span>
            </div>
            <div class="flex flex-row items-center space-x-3 hover:bg-gray-200 px-2 py-1">
              <v-icon>mdi-note-multiple-outline</v-icon>
              <span class="grow">Number of notes</span>
              <span>Unlimited</span>
            </div>
            <div class="flex flex-row items-center space-x-3 hover:bg-gray-200 px-2 py-1">
              <v-icon>mdi-folder-multiple-outline</v-icon>
              <span class="grow">Number of note category</span>
              <span>Unlimited</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section flex flex-col space-y-2 mb-4">
        <div class="">Actions</div>
        <div class="flex flex-col space-y-2 text-gray-500">
          <div class="flex flex-row items-center space-x-3">
            <span class="grow">Delete my account forever</span>
            <v-btn color="error">Delete account</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountView',

  data() {
    return {
    }
  }
}
</script>